/* eslint-disable @typescript-eslint/no-explicit-any */
// Add polyfills
import 'react-app-polyfill/stable';
import './polyfills';
// Init configs
import './config/init';
import './modules/locales/services/i18n';

// Add rest
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
// Other
import App from './modules/core/components/App/Loadable';
import FullscreenError, {
  onError,
} from './modules/core/components/Error/Fullscreen/FullscreenError';
import * as serviceWorker from './modules/core/services/serviceWorker';
// Initialize languages
import reportWebVitals, { sendToAnalytics } from './reportWebVitals';
// Configure redux store
import StorageSync from './modules/core/components/StorageSync/StorageSync';
import store from './modules/core/services/redux/configureStore';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(MOUNT_NODE);

root.render(
  <StrictMode>
    <ErrorBoundary FallbackComponent={FullscreenError} onError={onError}>
      <Provider store={store}>
        <StorageSync />
        <App />
      </Provider>
    </ErrorBoundary>
  </StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below.
serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint.

reportWebVitals(sendToAnalytics);
