// envs manager

// ? TODO: improve this to support process (cypress)
const ensImp = import.meta.env;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const envs: Dict<any> = {
  NODE_ENV: ensImp?.['NODE_ENV'] || ensImp?.['MODE'],
  ...ensImp,
};
