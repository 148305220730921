const i = {
  enabled: !0,
  /** Lower value = higher level */
  logLevel: 5,
  log: function(...e) {
    this.enabled && this.logLevel === 5 && console.log(...e);
  },
  logWith: function(e, ...l) {
    this.enabled && this.logLevel >= e && console.log(...l);
  },
  logType: function(e, ...l) {
    this.enabled && console[e](...l);
  },
  serviceLog: function(e, l, ...o) {
    this.enabled && console[e](`[${l}]: `, ...o);
  }
}, s = /* @__PURE__ */ (() => {
  const n = (o, ...t) => {
    e.console && i.logType(o, ...t);
  };
  let e = { console: !0 };
  const l = (o) => {
    throw o;
  };
  return {
    init: (o) => {
      e = { ...e, ...o };
    },
    c: n,
    console: n,
    show: (o, ...t) => console[o](...t),
    report: (o, t = "error") => {
      e.log && e.log(o), n(t, o);
    },
    raise: l,
    rescue: (o, t) => o instanceof t ? o : l(o)
  };
})();
export {
  s as errorService,
  i as logger
};
