var R = Object.defineProperty;
var U = (e, t, n) => t in e ? R(e, t, { enumerable: !0, configurable: !0, writable: !0, value: n }) : e[t] = n;
var T = (e, t, n) => U(e, typeof t != "symbol" ? t + "" : t, n);
import { logger as w, errorService as D } from "@waitroom/error-service";
import { retryWithBackoff as I, parseJson as k } from "@waitroom/utils";
const N = (e) => e ? `?${Object.keys(e).map((t) => `${t}=${encodeURIComponent(e[t])}`).join("&")}` : "";
class j {
  constructor() {
    T(this, "handlerRef", { id: -1 });
  }
  get handler() {
    return this.handlerRef.id;
  }
  set handler(t) {
    this.handlerRef.id = t;
  }
  clear() {
    clearTimeout(this.handlerRef.id);
  }
}
function B(e, t, n = new j()) {
  let i = !1;
  const r = () => {
    n.handler = setTimeout(() => {
      e(() => {
        i = !0, n.clear();
      }), i || r();
    }, t);
  };
  return r(), n;
}
var J = window.fetch, P = window.AbortController, O = window.Headers;
async function L(e, t = {}) {
  if (t = { ...t }, t.headers ? t.headers = new O(t.headers) : t.headers = new O(), t.version && console.assert(
    Array.isArray(t.version),
    "fetch(): `version` must be an array"
  ), t.parents && console.assert(
    Array.isArray(t.parents),
    "fetch(): `parents` must be an array"
  ), t.version && t.headers.set(
    "version",
    t.version.map(JSON.stringify).join(", ")
  ), t.parents && t.headers.set(
    "parents",
    t.parents.map(JSON.stringify).join(", ")
  ), t.subscribe && t.headers.set("subscribe", "true"), t.peer && t.headers.set("peer", t.peer), t.headers.set("X-Keep-Alive", "true"), t.cache = "no-cache", t.patches)
    if (console.assert(!t.body, "Cannot send both patches and body"), console.assert(
      typeof t.patches == "object",
      "Patches must be object or array"
    ), Array.isArray(t.patches) || (t.patches = [t.patches]), t.patches.length === 1) {
      let s = t.patches[0];
      t.headers.set("Content-Range", `${s.unit} ${s.range}`), t.headers.set(
        "Content-Length",
        `${new TextEncoder().encode(s.content).length}`
      ), t.body = s.content;
    } else
      t.headers.set("Patches", t.patches.length), t.body = t.patches.map((s) => {
        var g = `content-length: ${new TextEncoder().encode(s.content).length}`, h = `content-range: ${s.unit} ${s.range}`;
        return `${g}\r
${h}\r
\r
${s.content}\r
`;
      }).join(`\r
`);
  var n = t.signal, i = new P();
  t.signal = i.signal, n && n.addEventListener("abort", () => i.abort());
  var r = await J(e, t);
  r.subscribe = a, r.subscription = { [Symbol.asyncIterator]: f };
  const o = r.headers.get("X-Keep-Alive");
  function a(s, g) {
    if (!r.ok) throw new Error("Request returned not ok status:", r.status);
    if (r.bodyUsed)
      throw new Error("This response's body has already been read", r);
    W(
      r.body,
      // Each time something happens, we'll either get a new
      // version back, or an error.
      (h, b) => {
        if (!b)
          s(h);
        else {
          const v = i.signal.aborted;
          if (i.abort(), g) g(b, v);
          else throw "Unhandled network error in subscription";
        }
      },
      o
    );
  }
  function f() {
    var s = !1, g = [], h = null, b = null;
    return {
      async next() {
        if (g.length > 0) return { done: !1, value: g.shift() };
        var v = new Promise((l, A) => {
          h = l, b = A;
        });
        s || (s = !0, a(
          (l) => h(l),
          (l) => b(l)
        ));
        var p = await v;
        return h = (l) => g.push(l), b = (l) => {
          throw l;
        }, { done: !1, value: p };
      }
    };
  }
  return r;
}
async function W(e, t, n) {
  const i = e.getReader(), r = H(t);
  let o;
  if (n) {
    const a = (parseInt(n) + 15) * 1e3;
    o = B(() => r.checkTimeout(a), 1e4), r.keepAlive = !0;
  }
  for (; ; ) {
    let a, f;
    try {
      const s = await i.read();
      a = s.done, f = s.value;
    } catch (s) {
      o && o.clear(), r.timedOut || t(null, s);
      return;
    }
    if (a) {
      console.debug("Connection closed."), o && o.clear(), r.timedOut || t(null, "Connection closed");
      return;
    }
    r.read(f);
  }
}
const H = (e) => ({
  // A parser keeps some parse state
  state: { input: [] },
  // And reports back new versions as soon as they are ready
  cb: e,
  // Timestamp of last input
  last: Date.now(),
  timedOut: !1,
  keepAlive: !1,
  // You give it new input information as soon as you get it, and it will
  // report back with new versions as soon as it finds them.
  read(t) {
    const n = t;
    if (this.last = Date.now(), !(this.keepAlive && n.length > 12 && n[0] === 88 && n[1] == 45 && n[2] === 75 && n[3] === 101 && n[4] === 101 && n[5] === 112 && n[6] === 45 && n[7] === 65 && n[8] === 108 && n[9] === 105 && n[10] === 118 && n[11] === 101))
      for (this.state.input.push(...t); this.state.input.length; ) {
        try {
          this.state = K(this.state);
        } catch (i) {
          this.cb(null, i);
          return;
        }
        if (this.state.result === "success")
          this.cb({
            version: this.state.version,
            parents: this.state.parents,
            body: this.state.body,
            patches: this.state.patches,
            // Output extra_headers if there are some
            extra_headers: m(this.state.headers)
          }), this.state = { input: this.state.input };
        else if (this.state.result === "error") {
          this.cb(null, this.state.message);
          return;
        }
        if (this.state.result == "waiting") break;
      }
  },
  checkTimeout(t) {
    const n = Date.now() - this.last;
    w.log(`Braid interval diff = ${n}`), n > t && (w.logWith(3, "Braid connection timed out"), this.timedOut || e(null, "Connection timed out"), this.timedOut = !0);
  }
});
function K(e) {
  if (!e.headers) {
    const t = S(e.input);
    if (t.result === "error") return t;
    if (t.result === "waiting")
      return e.result = "waiting", e;
    e.headers = t.headers, e.version = e.headers.version, e.parents = e.headers.parents, e.input = t.input;
  }
  return q(e);
}
function S(e) {
  const t = z(e);
  if (!t) return { result: "waiting" };
  const n = t.header_string, i = n.length, r = {}, o = /(:?[\w-_]+):\s?(.*)\r?\n?/gy;
  let a, f = !1;
  for (; a = o.exec(n); )
    r[a[1].toLowerCase()] = a[2], o.lastIndex === i && (f = !0);
  return f ? ("version" in r && (r.version = JSON.parse("[" + r.version + "]")), "parents" in r && (r.parents = JSON.parse("[" + r.parents + "]")), "patches" in r && (r.patches = JSON.parse(r.patches)), e = t.remaining_bytes, { result: "success", headers: r, input: e }) : {
    result: "error",
    message: 'Parse error in headers: "' + JSON.stringify(n.substr(o.lastIndex)) + '"',
    headers_so_far: r,
    last_index: o.lastIndex,
    headers_length: i
  };
}
function C(e) {
  const t = e.match(/(\S+)( (.*))?/);
  return t && { unit: t[1], range: t[3] || "" };
}
function q(e) {
  const t = parseInt(e.headers["content-length"]);
  if (isNaN(t)) {
    if (e.headers.patches != null) {
      e.patches = e.patches || [];
      let n = e.patches[e.patches.length - 1];
      for (; !(e.patches.length === e.headers.patches && (e.patches.length === 0 || "content" in n)); ) {
        if ((!n || "content" in n) && (n = {}, e.patches.push(n)), !("headers" in n)) {
          const i = S(e.input);
          if (i.result === "error") return i;
          if (i.result === "waiting")
            return e.result = "waiting", e;
          n.headers = i.headers, e.input = i.input;
        }
        {
          if (!("content-length" in n.headers))
            return {
              result: "error",
              message: "no content-length in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          if (!("content-range" in n.headers))
            return {
              result: "error",
              message: "no content-range in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          const i = parseInt(n.headers["content-length"]);
          if (e.input.length < i)
            return e.result = "waiting", e;
          const r = C(n.headers["content-range"]);
          if (!r)
            return {
              result: "error",
              message: "cannot parse content-range in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          n.unit = r.unit, n.range = r.range, n.content = new TextDecoder("utf-8").decode(
            new Uint8Array(e.input.slice(0, i))
          ), n.extra_headers = m(n.headers), delete n.headers, e.input = e.input.slice(i);
        }
      }
      return e.result = "success", e;
    }
  } else {
    if (t > e.input.length)
      return e.result = "waiting", e;
    if (e.result = "success", e.headers["content-range"]) {
      const n = C(e.headers["content-range"]);
      if (!n)
        return {
          result: "error",
          message: "cannot parse content-range",
          range: e.headers["content-range"]
        };
      e.patches = [
        {
          unit: n.unit,
          range: n.range,
          content: new TextDecoder("utf-8").decode(
            new Uint8Array(e.input.slice(0, t))
          )
          // Question: Perhaps we should include headers here, like we do for
          // the Patches: N headers below?
          // headers: state.headers
        }
      ];
    } else
      e.body = new TextDecoder("utf-8").decode(
        new Uint8Array(e.input.slice(0, t))
      );
    return e.input = e.input.slice(t), e;
  }
  return {
    result: "error",
    message: "cannot parse body without content-length or patches header"
  };
}
function m(e) {
  const t = Object.assign({}, e), n = [
    "version",
    "parents",
    "patches",
    "content-length",
    "content-range"
  ];
  for (let i = 0; i < n.length; i++)
    delete t[n[i]];
  if (Object.keys(t).length !== 0)
    return t;
}
function z(e) {
  let t = 0;
  for (; e[t] === 13 || e[t] === 10; )
    t++;
  if (t === e.length)
    return null;
  let n = t, i = 0;
  for (; n < e.length; ) {
    if (e[n] === 10 && e[n + 1] === 10) {
      i = 2;
      break;
    }
    if (e[n] === 10 && e[n + 1] === 13 && e[n + 2] === 10) {
      i = 3;
      break;
    }
    if (e[n] === 13 && e[n + 1] === 10 && e[n + 2] === 10) {
      i = 3;
      break;
    }
    if (e[n] === 13 && e[n + 1] === 10 && e[n + 2] === 13 && e[n + 3] === 10) {
      i = 4;
      break;
    }
    n++;
  }
  if (n === e.length)
    return null;
  const r = e.slice(t, n), o = new TextDecoder("utf-8").decode(
    new Uint8Array(r)
  );
  return {
    remaining_bytes: e.slice(n + i),
    header_string: o
  };
}
const F = L, M = {
  _connections: {},
  config: { interceptors: {} },
  close: function(e) {
    const t = this._connections[e];
    t && (w.logWith(3, "braid: close", e), t.abort.abort(), delete this._connections[e]);
  },
  fetch: async function(e) {
    const { onError: t } = this.config.interceptors, {
      key: n,
      method: i = "GET",
      shouldReconnect: r = !0,
      subscribe: o = !0,
      headers: a = {},
      onFetched: f,
      onPatch: s,
      params: g,
      signal: h,
      skipRetryErrorCodes: b = [401, 404],
      ...v
    } = e, p = n || v.url || "";
    if (w.log("braid: fetch", p), this._connections[p]) {
      const c = `Connection already exists ${p}`;
      if (w.logWith(3, c), !r) throw new Error(c);
      this.close(p);
    }
    const l = N(g), A = `${v.baseURL}${v.url}${l}`, _ = new AbortController();
    if (h) {
      if (h.aborted) return;
      h.addEventListener("abort", () => {
        w.logWith(3, "braid: abort", p), _.abort(), delete this._connections[p];
      });
    }
    const E = () => new Promise(
      async (c, x) => {
        const $ = F(A, {
          method: i,
          headers: a,
          subscribe: o,
          signal: _.signal,
          ...v
        });
        this._connections[p] = {
          abort: _,
          connection: $
        };
        let y;
        try {
          y = await $;
        } catch (d) {
          return x(d);
        }
        if (w.log("braid: connection", y), y.status > 399)
          return x({
            // @ts-expect-error types are wrong
            code: y.status,
            data: (y == null ? void 0 : y.data) || y
          });
        y.subscribe(
          (d) => {
            if (d.body) {
              const u = k(d.body);
              return w.log("braid: body", u), f && f(u), u ? c(u) : x(d);
            }
            d.patches && s && (w.log("braid: patch", d.patches), s(
              d.patches.map(
                (u) => ({
                  op: u.unit,
                  path: u.range,
                  value: u.content ? k(u.content) : void 0
                })
              )
            ));
          },
          (d, u) => {
            if (u) return c(void 0);
            w.logWith(1, "Braid error", d), x(d);
          }
        );
      }
    );
    try {
      return await I(E, {
        retries: -1,
        // skip retry
        filterError: b ? (c) => !b.includes(c == null ? void 0 : c.status) && !b.includes(c == null ? void 0 : c.code) : void 0
      });
    } catch (c) {
      if (D.report(c), _.abort(), !t) throw c;
      return t(e, c);
    }
  }
};
export {
  M as braidService,
  N as buildQueryString,
  B as setIntervalWithTimeout
};
