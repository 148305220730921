// browser polyfills
Promise.allSettled =
  Promise.allSettled ||
  ((promises) =>
    Promise.all(
      promises.map((p) =>
        p
          .then((value) => ({
            status: 'fulfilled',
            value,
          }))
          .catch((reason) => ({
            status: 'rejected',
            reason,
          })),
      ),
    ));

// globalThis polyfill
(function () {
  if (typeof globalThis === 'object') return;
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Object.prototype, '__magic__', {
    get: function () {
      return this;
    },
    configurable: true,
  });
  // eslint-disable-next-line no-undef
  __magic__.globalThis = __magic__; // lolwat
  delete Object.prototype.__magic__;
})();
