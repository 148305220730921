import { Role as f, rolesWithId as $, VideoFileExtension as E, SocialKeys as R } from "@waitroom/models";
import l from "dayjs";
const j = (t) => t != null, g = (t) => typeof t == "function", F = (t, ...e) => e.some((n) => typeof t === n), v = (t, e) => e in t, b = (t, e) => t instanceof e, L = (...t) => (e) => t.reduceRight((n, r) => r(n), e), Y = (...t) => (e) => t.reduce((n, r) => r(n), e), x = (t) => (...e) => Promise.resolve(t(...e)), C = (t) => (e) => {
  try {
    return t();
  } catch (n) {
    return e ? e(n) : n;
  }
}, U = (t) => async (e) => {
  try {
    return await t();
  } catch (n) {
    return e ? e(n) : n;
  }
}, P = async (t, e) => {
  var o;
  const n = {
    delay: 300,
    retries: 2,
    ...e
  };
  let r;
  for (let s = 1; s <= n.retries; s++)
    try {
      return await t();
    } catch (i) {
      if (r = i, !(((o = n.filterError) == null ? void 0 : o.call(n, i)) ?? !0)) throw i;
      s < n.retries && await new Promise(
        (c) => setTimeout(c, (n.delay ?? 300) * s)
      );
    }
  throw r;
}, V = (t, e) => {
  let n = Date.now();
  const r = async (o = 1) => {
    var c;
    const s = {
      initialDelay: 300,
      maxDelay: 3e3,
      retries: 3,
      resetTime: 1e4,
      // default to 10 seconds to reset backoff
      ...e
    }, i = Date.now();
    i - n > s.resetTime && (o = 1), n = i;
    try {
      return await t();
    } catch (a) {
      if (!(((c = s.filterError) == null ? void 0 : c.call(s, a)) ?? !0) || s.retries !== -1 && o >= s.retries) throw a;
      const S = Math.min(
        s.initialDelay * 2 ** (o - 1),
        s.maxDelay
      );
      return new Promise(
        (w) => setTimeout(() => w(r(o + 1)), S)
      );
    }
  };
  return r();
}, _ = (t) => (e) => (...n) => t(n) ? e(n) : void 0, W = () => typeof window < "u", q = (t, e) => {
  const n = {};
  if (!t) return n;
  for (let r = 0; r < t.length; r++) {
    const o = t[r];
    e ? e(n, o) : n[String(o)] = o;
  }
  return n;
}, z = (t, e) => t ? (Array.isArray(e) ? e : [e]).every((r) => t.includes(r)) : !1, H = (t, e) => t ? (Array.isArray(e) ? e : [e]).some((r) => t.includes(r)) : !1, G = (t, e, n = ", ") => {
  if (!t) return "";
  const r = g(e) ? e : (o) => String(o[e] ?? "");
  return t.map(r).filter(Boolean).join(n);
}, J = (t, e, n) => {
  if (!t) return "";
  const r = g(e) ? e : (c) => String(c[e] ?? ""), o = t.map(r).filter(Boolean);
  if (o.length <= 1) return o.join(", ");
  const s = [...o], i = s.pop();
  return `${s.join(", ")} ${n} ${i}`;
};
function Z(t, e) {
  return [
    ...t.filter((n) => e.indexOf(n) === -1),
    ...e.filter((n) => t.indexOf(n) === -1)
  ];
}
function K(t) {
  const e = Math.floor(Math.random() * t.length);
  return t[e];
}
function Q(t, e) {
  const n = {}, r = [];
  for (const o of t) {
    const s = o[e];
    n[s] || (n[s] = !0, r.push(o));
  }
  return r;
}
const d = [
  "B",
  "KB",
  "MB",
  "GB",
  "TB",
  "PB",
  "EB",
  "ZB",
  "YB"
], m = 1024, k = (t, e = 2) => {
  if (t === 0) return "0 B";
  const n = e < 0 ? 0 : e, r = Math.floor(Math.log(t) / Math.log(m));
  return `${parseFloat((t / m ** r).toFixed(n))} ${d[r]}`;
}, X = (t, e = "B", n = "MB", r = 2) => {
  if (!t || e === n) return t;
  const o = r < 0 ? 0 : r, s = d.indexOf(n), i = d.indexOf(e);
  if (s < 0 || i < 0) return t;
  const c = s - i;
  return parseFloat((t / m ** c).toFixed(o));
}, u = (t) => (e) => e ? `${t}[${e}]` : `${t}`, tt = (t, e) => t ? t.map(
  (n) => u(n)(n in $ ? e : void 0)
) : void 0, et = u(f.HOST), nt = u(f.GUEST), rt = u(f.VIEWER), ot = u(
  f.SESSION_PRODUCER
), st = u(f.SESSION_OWNER), it = u(
  f.SESSION_INFO_VIEWER
), ct = u(f.ACTIVE_STREAMS), ut = (t) => (e) => `space:${u(t)(e)}`, N = (t) => {
  const [, e] = t.split(":");
  if (!e) return;
  const [n, r] = e.split("[");
  return {
    role: n,
    id: r && r.replace("]", "")
  };
}, ft = (t) => (N(t) || {}).id, h = /:00/, at = (t, e, {
  separator: n = " — ",
  date: r = "dddd, MMM D, YYYY",
  time: o = "h:mm A"
} = {}) => {
  const s = l(t).format(`${r}${n}${o}`).replace(h, ""), i = l(e).format(o).replace(h, "");
  return `${s} - ${i}`;
}, p = (t) => {
  const n = ~~((t < 0 ? 0 : t) / 1e3), r = ~~(n / 60), o = ~~(r / 60);
  return {
    days: ~~(o / 24),
    hours: o % 24,
    minutes: r % 60,
    seconds: n % 60
  };
}, y = {
  days: "d",
  hours: "h",
  minutes: "m",
  seconds: "s"
}, O = Object.keys(y), lt = (t, e = {}) => {
  const n = p(t), r = { ...y, ...e };
  return O.reduce(
    (o, s) => n[s] > 0 ? `${o}${n[s]}${r[s]} ` : o,
    ""
  ).trim();
}, dt = (t) => typeof t == "number" ? t * 1e3 : t, mt = (t, e) => {
  const n = l(t), r = [`FREQ=${e}`];
  return e === "weekly" ? r.push(`BYDAY=${n.format("dd")}`) : e === "monthly" ? r.push(`BYMONTHDAY=${n.format("D")}`) : e === "yearly" && r.push(`BYMONTH=${n.format("M")};BYMONTHDAY=${n.format("D")}`), r.join(";").toUpperCase();
}, ht = (t, e = ":") => {
  const { days: n, hours: r, minutes: o, seconds: s } = p(t), i = [];
  let c = r;
  return n > 0 && (c += n * 24), c > 0 ? (i.push(String(c)), i.push(String(o).padStart(2, "0"))) : i.push(String(o)), i.push(String(s).padStart(2, "0")), i.join(e);
}, gt = (t, e, n, r) => {
  e.forEach((o) => {
    t.addEventListener(o, n, r);
  });
}, pt = (t, e, n, r) => {
  e.forEach((o) => {
    t.removeEventListener(o, n, r);
  });
}, yt = (t, e, n, r = {}) => t.reduce((o, s) => {
  const i = n[s] ?? e[s];
  return o[s] = s in r || i === void 0 ? i : String(i), o;
}, {});
function St(t) {
  try {
    return JSON.parse(t), !0;
  } catch {
    return !1;
  }
}
function wt(t, e) {
  if (!t) return e;
  try {
    return JSON.parse(t);
  } catch {
    return e;
  }
}
const A = typeof navigator < "u" && "locks" in navigator, $t = (t, e, n = {}) => A ? (...r) => navigator.locks.request(t, n, async () => await e(...r)) : e, Et = (t = 0, e = Number.MAX_SAFE_INTEGER) => Math.floor(Math.random() * (e - t + 1)) + t, Rt = (t, e, n = "+ ") => t > e ? `${n}${e}` : `${t}`, Nt = (t) => t < 1e3 ? `${t}` : t < 1e6 ? `${Number((t / 1e3).toFixed(1))}k` : `${Number((t / 1e6).toFixed(1))}m`, B = (t) => t != null && (typeof t == "string" || typeof t == "number"), Ot = (t) => t !== null && typeof t == "object" && !Array.isArray(t), I = (t) => Object.keys(t);
function At(t, ...e) {
  return e.length ? e.reduce((r, o) => {
    const { [o]: s, ...i } = r;
    return i;
  }, t) : t;
}
function Bt(t, e, n) {
  if (!t) return n;
  const o = (typeof e == "string" ? e.split(".") : e).reduce(
    (s, i) => s && s[i] !== void 0 ? s[i] : void 0,
    t
  );
  return o !== void 0 ? o : n;
}
const It = (t, e) => t ? t[e] : void 0;
function M(t, e, n) {
  return I({ ...t, ...e }).reduce((r, o) => ((n ? n(t[o]) === n(e[o]) : t[o] === e[o]) || (r[o] = e[o]), r), {});
}
const Mt = (t, e) => Object.entries(M(t, e)).length === 0, Tt = (t, e) => {
  if (!t) return;
  const n = {};
  for (let r = 0; r < t.length; r++) {
    const o = t[r], s = typeof e == "function" ? e(o) : o[e], i = B(s) ? s : String(s);
    n[i] = (n[i] || []).concat(o);
  }
  return n;
}, Dt = (t, e = E.HLS) => {
  for (let n = t.length - 1; n > -1; n -= 1)
    if (t[n].endsWith(e))
      return t[n];
  return t[0];
}, jt = (t, e, n = "...") => !t || t.length <= e ? t : `${t.substring(0, e)}${n}`, Ft = (t) => !!t && t.trim() !== "", vt = (t) => !!t && String(t).toLowerCase() !== "false" && t !== "0", bt = ({
  text: t,
  /** Lenght of the step for reducing the size */
  lengthStep: e,
  /** Size reduction for each step  */
  sizeStep: n = 0.1,
  /** Minimum size */
  min: r = 0.5
}) => {
  const o = (t || "").length, s = 1 - Math.floor(o / e) * n;
  return s < r ? r : s;
}, Lt = `  
`, Yt = (t, e = "-") => t.join(e), xt = (t = "") => t === "" ? "" : ` ${t}`, Ct = ({
  params: t,
  relative: e = !1
} = {}) => {
  const n = new URL(window.location.href);
  if (t)
    for (const r of Object.keys(t))
      n.searchParams.set(r, String(t[r]));
  return e ? `${n.pathname}${n.search}` : n.href;
}, Ut = ({
  relative: t = !1,
  queryString: e = !0
} = {}) => {
  const n = new URL(window.location.href);
  return e || (n.search = ""), t ? `${n.pathname}${n.search}` : n.href;
}, Pt = (t) => t ? /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i.test(
  t
) : !1, Vt = (t) => !t || t.indexOf("://") === -1 && t.indexOf("//") !== 0, _t = (t, e) => {
  const n = new URL(t);
  for (const r of Object.keys(e))
    n.searchParams.set(r, String(e[r]));
  return n.href;
};
function Wt(t) {
  return t && t.replace(/(wss?:\/\/|wss?:)/g, "").replace("/socket", "").replace("/websocket", "");
}
const qt = (t) => t ? t == null ? void 0 : t.reduce((e, n) => (n.platform === "email" || (e[n.platform] = n.url), e), {}) : void 0, zt = (t) => t ? R.reduce((e, n) => {
  const r = t[n];
  return !r || n === "email" || e.push({
    platform: n,
    url: r
  }), e;
}, []) : void 0;
export {
  _t as addQueryParams,
  Mt as areDeepEqual,
  q as arrayToMap,
  gt as bindEvents,
  Ct as buildCurrentUrl,
  Yt as buildKey,
  bt as calcResponsiveSize,
  L as compose,
  z as containsAll,
  H as containsAny,
  X as convertBytes,
  M as diff,
  Z as difference,
  lt as durationToFormat,
  qt as flattenSocials,
  k as formatBytes,
  at as fromTo,
  Bt as get,
  ct as getActiveStreamsIdRoleName,
  It as getByKey,
  ut as getChannelName,
  Ut as getCurrentUrl,
  Wt as getDomainKeyFromUrl,
  ht as getFormattedLength,
  nt as getGuestIdRoleName,
  et as getHostIdRoleName,
  ft as getIdFromChannelName,
  u as getIdRoleName,
  K as getRandomItem,
  Et as getRandomNumber,
  Dt as getRecordingURL,
  tt as getRolesNames,
  it as getSessionInfoViewerIdRoleName,
  st as getSessionOwnerIdRoleName,
  ot as getSessionProducerIdRoleName,
  rt as getViewerIdRoleName,
  Tt as groupBy,
  _ as ifThen,
  j as isDefined,
  g as isFunction,
  b as isInstanceOf,
  St as isJsonString,
  Ft as isNotEmpty,
  Ot as isObject,
  Vt as isRelativeUrl,
  v as isType,
  F as isTypeof,
  B as isValidKey,
  Pt as isValidUrl,
  G as joinOn,
  J as joinWithAnd,
  I as keysOf,
  yt as mergeValues,
  m as multiplier,
  A as navigatorLockSupported,
  Lt as newline,
  At as omit,
  N as parseChannel,
  wt as parseJson,
  p as parseMiliseconds,
  Y as pipe,
  x as promisify,
  P as retry,
  V as retryWithBackoff,
  Nt as shortenNumber,
  d as sizes,
  vt as toBoolean,
  dt as toEpoch,
  Rt as toLimit,
  mt as toRRule,
  jt as truncate,
  C as tryCatch,
  U as tryCatchAsync,
  pt as unbindEvents,
  zt as unflattenSocials,
  Q as uniqBy,
  W as windowDefined,
  $t as withLock,
  xt as withSpace
};
