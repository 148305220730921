import { config as commonConfig } from '@waitroom/config';
import { FeatureFlags } from '@waitroom/models';
import { parseJson, toBoolean } from '@waitroom/utils';
import pkg from '../../package.json';
import { envs } from '../modules/core/services/envs';
import type { Environment } from './types';

let _baseUrl = envs.VITE_BASE_URL || window.location.origin;
// patch amplify preview web app
if (envs.VITE_BASE_URL === 'AMPLIFY_PREVIEW') {
  _baseUrl = `https://pr-${envs.AWS_PULL_REQUEST_ID}.${envs.AWS_APP_ID}.amplifyapp.com`;
}
export const baseUrl = _baseUrl;

const protocol = Boolean(envs.VITE_IS_LOCAL) ? 'http' : 'https';

const luxor = String(`${protocol}://${envs.VITE_LUXOR_DOMAIN}`);
const wormhole = String(`${protocol}://${envs.VITE_WORMHOLE_DOMAIN}`);

const env = (envs.NODE_ENV || 'production') as Environment;
const customEnv = (envs.VITE_ENVIRONMENT || env) as Environment;
const s3Default = 'https://public-rumi.s3.amazonaws.com';

const baseConfig = {
  environment: env,
  customEnv: customEnv,
  isProd: customEnv === 'production',
  isDev: customEnv === 'development',
  version: pkg.version,
  baseUrl,
  metaData: {
    imageURL: `${baseUrl}/assets/images/meta/og-image.jpg`,
  },
  dateTime: {
    timezone: {
      default: 'America/New_York',
      current: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  },
  domains: {
    luxor,
    wormhole,
    cdn: envs.VITE_CDN_DOMAIN,
    s3: {
      default: s3Default,
      images: `${s3Default}/images`,
      logo: `${s3Default}/images/logo`,
      image: envs.VITE_S3_IMAGE_DOMAIN,
    },
  },
  cookieConsent: {
    id: 'CookieConsent',
    expiration: 356, // 1 year
    dismiss: 7, // 7 days
  },
  session: {
    speakers: {
      min: 1,
      max: 50,
    },
  },
  google: {
    clientId: envs.VITE_GOOGLE_CLIENT_ID,
    recaptcha: {
      key: envs.VITE_GOOGLE_RECAPTCHA_KEY,
      enabled:
        envs.VITE_GOOGLE_RECAPTCHA_KEY &&
        toBoolean(envs.VITE_USE_GOOGLE_RECAPTCHA),
    },
  },
  apple: {
    clientId: envs.VITE_APPLE_CLIENT_ID,
  },
  livekit: {
    serverUrl: envs.VITE_LIVEKIT_SERVER_URL,
  },
  typeform: {
    ids: {
      postMeeting: envs.VITE_TYPEFORM_MEETING_ENDED_ID || 'ftnTEeja',
      earlyAccess: 'gZ4Tb3Yf',
      inMeeting: envs.VITE_TYPEFORM_IN_MEETING_ID || 'BwxSvGel',
    },
  },
  paddle: {
    id: envs.VITE_PADDLE_ID ? Number(envs.VITE_PADDLE_ID) : undefined,
  },
  getStream: {
    key: envs.VITE_GETSTREAM_KEY,
  },
  maintenance: toBoolean(envs.VITE_MAINTENANCE),
  featureFlags: parseJson<FeatureFlags>(envs.VITE_FEATURE_FLAGS || '{}') || {},
};

// merge configs
// ! does not do a deep merge
export const config = {
  ...commonConfig,
  ...baseConfig,
};
