import { configureStore, EnhancedStore, Middleware } from '@reduxjs/toolkit';
import {
  authListenerMiddleware as commonAuthListenerMiddleware,
  CommonState,
} from '@waitroom/common';
import { Reducer } from 'redux';
import dynamicMiddlewares from 'redux-dynamic-middlewares';
import { config } from '../../../../config';
import { authListenerMiddleware } from '../../../auth/services/listener';
import { createReducer } from './reducers';

export function configureAppStore(
  initialState: Partial<RootState> = {},
  reducers?: Record<string, Reducer>,
): EnhancedStore {
  // Create the store with saga middleware
  const middlewares: Middleware<CommonState>[] = [
    commonAuthListenerMiddleware.middleware,
    authListenerMiddleware.middleware,
  ];

  const store = configureStore({
    preloadedState: initialState,
    reducer: createReducer(reducers),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: false,
        immutableCheck: {
          ignore: ['router'],
        },
        serializableCheck: false,
      }).prepend(...middlewares, dynamicMiddlewares),
    devTools: !config.isProd,
  });

  return store;
}

const store = configureAppStore();
export type AppDispatch = typeof store.dispatch;
export type RootState = CommonState;

export default store;
