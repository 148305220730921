import { braidService as b } from "@waitroom/braid";
import { braidService as Se } from "@waitroom/braid";
import { bearerValue as j, httpService as s } from "@waitroom/http-client";
import { httpService as Pe } from "@waitroom/http-client";
let u;
const N = (e) => {
  u = {
    ...u,
    ...e
  };
}, ae = (e) => {
  N(e);
}, I = async (e) => typeof e == "function" ? await e() : await e, V = async () => {
  const e = await I(u.getAuthToken);
  return {
    Authorization: e ? j(e) : void 0
  };
}, W = async () => {
  const [e, t] = await Promise.allSettled([
    I(u.getFingerPrint),
    I(u.getClientDetails)
  ]);
  return {
    "X-Fingerprint": e.status === "fulfilled" ? e.value : "",
    "X-Client-Info": JSON.stringify(t.status === "fulfilled" ? t.value : {}),
    "Accept-Language": "en"
    // TODO: This should be updated to pick the app's language
  };
}, _ = (e, t) => `${e || u.baseURL}/${t || u.version}`, z = async (e, t) => ({
  ...await W(),
  ...e ? await V() : {},
  ...t
}), U = (e) => async (t, { version: a, baseURL: i, headers: c, ...f } = {}) => ({
  url: t,
  baseURL: _(i, a),
  headers: await z(e, c),
  ...f
}), g = U(!1), n = U(!0), se = {
  getFeed: async (e, t, a) => b.fetch(
    await n("/ai-feed/get", {
      ...u.defaultBraidParams,
      ...a,
      params: {
        ...a == null ? void 0 : a.params,
        sessionID: e,
        sessionRecurrenceID: t
      }
    })
  ),
  event: async (e, t, a) => s.post(
    await n("/ai-feed/event", {
      ...u.defaultBraidParams,
      ...a,
      data: {
        ...a == null ? void 0 : a.data,
        sessionID: e,
        sessionRecurrenceID: t
      }
    })
  ),
  updateFeed: async (e, t) => s.patch(await n(`/ai-feed/${e}`, t)),
  getSummary: async (e, t, a) => s.get(
    await n(
      `/post-session-summaries/by-session-ids/${e}`,
      {
        ...a,
        params: {
          formats: "md",
          ...a == null ? void 0 : a.params,
          sessionRecurrenceID: t
        }
      }
    )
  ),
  internalCopyAIFeed: async (e) => s.post(await n("/ai-feed/copy", e))
}, ne = "/auth", ie = {
  verifyOTP: async (e) => s.post(await g("/auth/verify-otp", e)),
  verifyOAuthOTP: async (e) => s.post(await g("/oauth/verify-otp", e)),
  requestOTP: async (e) => s.post(await g("/auth/request-otp", e)),
  requestOAuthOTP: async (e) => s.post(await g("/oauth/request-otp", e)),
  verifyAuthTokenProvider: async (e) => s.post(await g("/auth/social-token", e)),
  verifyOAuthTokenProvider: async (e) => s.post(await g("/oauth/social-token", e)),
  verifyAuthCodeProvider: async (e) => s.post(await g("/auth/social-code", e)),
  verifyOAuthCodeProvider: async (e) => s.post(await g("/oauth/social-code", e)),
  refreshToken: async (e) => s.put(await g("/auth/refresh-auth-token", e)),
  logout: async (e) => s.post(await n("/auth/logout", e)),
  hostOptIn: async (e) => s.patch(
    await n("/auth/user/host-opt-in", e)
  ),
  user: async (e) => s.get(await n("/auth/user", e))
}, ce = {
  getPresignedUrl: async (e) => s.get(
    await n("/image-uploads/presigned-url", e)
  )
}, h = "/integrations", l = `${h}/salesforce`, oe = {
  getProviders: async (e) => s.get(await g(`${h}/providers`, e)),
  getConnections: async (e) => s.get(
    await n(`${h}/connections`, e)
  ),
  getAction: async (e, t, a) => s.get(
    await n(`${h}/${e}/${t}`, a)
  ),
  callAction: async (e, t, a) => s.post(
    await n(`${h}/${e}/${t}`, a)
  ),
  getFromAbsoluteURL: async (e, t) => s.get(await n("", { url: e, ...t })),
  authorize: async (e) => s.get(
    await n(`${h}/authorize`, e)
  ),
  disconnect: async (e) => s.delete(
    await n(`${h}/disconnect`, e)
  ),
  salesforce: {
    createBinding: async (e) => s.post(await n(`${l}/bindings`, e)),
    getBindingById: async (e, t) => s.get(
      await n(
        `${l}/bindings/${e}`,
        t
      )
    ),
    getBindingByIdBraid: async (e, t) => b.fetch(
      await n(`${l}/bindings/${e}`, {
        ...t,
        ...u.defaultBraidParams
      })
    ),
    deleteBinding: async (e, t) => s.delete(
      await n(
        `${l}/bindings/${e}`,
        t
      )
    ),
    getAccountById: async (e, t) => s.get(
      await n(
        `${l}/accounts/${e}`,
        t
      )
    ),
    getOpportunityById: async (e, t) => s.get(
      await n(
        `${l}/opportunities/${e}`,
        t
      )
    ),
    listBindableRecordsAsOptions: async (e) => s.get(
      await n(
        `${l}/-/list-bindable-records-as-options`,
        e
      )
    ),
    createContact: async (e, t) => s.post(
      await n(`${l}/contacts`, {
        data: e,
        ...t
      })
    ),
    getContacts: async (e, t, a) => s.get(
      await n(
        `${l}/contacts/-/list-related-to-record`,
        {
          params: {
            ...a == null ? void 0 : a.params,
            recordId: e,
            recordType: t
          },
          ...a
        }
      )
    ),
    getLeadById: async (e, t) => s.get(
      await n(
        `${l}/leads/${e}`,
        t
      )
    ),
    getByOwnerSessionRecurrence: async (e, t, a, i) => s.get(
      await n(
        `${l}/bindings/-/get-by-owner-session-recurrence`,
        {
          params: {
            ownerId: e,
            sessionId: t,
            sessionRecurrenceId: a,
            ...i == null ? void 0 : i.params
          },
          ...i
        }
      )
    )
  }
};
async function J(e, t) {
  const a = e.getReader();
  let i;
  for (; !(i = await a.read()).done; )
    t(i.value);
}
function X(e) {
  let t, a, i, c = !1;
  return function(d) {
    t === void 0 ? (t = d, a = 0, i = -1) : t = G(t, d);
    const o = t.length;
    let r = 0;
    for (; a < o; ) {
      c && (t[a] === 10 && (r = ++a), c = !1);
      let y = -1;
      for (; a < o && y === -1; ++a)
        switch (t[a]) {
          case 58:
            i === -1 && (i = a - r);
            break;
          case 13:
            c = !0;
          case 10:
            y = a;
            break;
        }
      if (y === -1)
        break;
      e(t.subarray(r, y), i), r = a, i = -1;
    }
    r === o ? t = void 0 : r !== 0 && (t = t.subarray(r), a -= r);
  };
}
function M(e, t, a) {
  let i = L();
  const c = new TextDecoder();
  return function(d, o) {
    if (d.length === 0)
      a == null || a(i), i = L();
    else if (o > 0) {
      const r = c.decode(d.subarray(0, o)), y = o + (d[o + 1] === 32 ? 2 : 1), v = c.decode(d.subarray(y));
      switch (r) {
        case "data":
          i.data = i.data ? i.data + `
` + v : v;
          break;
        case "event":
          i.event = v;
          break;
        case "id":
          e(i.id = v);
          break;
        case "retry":
          const $ = parseInt(v, 10);
          isNaN($) || t(i.retry = $);
          break;
      }
    }
  };
}
function G(e, t) {
  const a = new Uint8Array(e.length + t.length);
  return a.set(e), a.set(t, e.length), a;
}
function L() {
  return {
    data: "",
    event: "",
    id: "",
    retry: void 0
  };
}
var K = function(e, t) {
  var a = {};
  for (var i in e) Object.prototype.hasOwnProperty.call(e, i) && t.indexOf(i) < 0 && (a[i] = e[i]);
  if (e != null && typeof Object.getOwnPropertySymbols == "function")
    for (var c = 0, i = Object.getOwnPropertySymbols(e); c < i.length; c++)
      t.indexOf(i[c]) < 0 && Object.prototype.propertyIsEnumerable.call(e, i[c]) && (a[i[c]] = e[i[c]]);
  return a;
};
const R = "text/event-stream", Q = 1e3, D = "last-event-id";
function Y(e, t) {
  var { signal: a, headers: i, onopen: c, onmessage: f, onclose: d, onerror: o, openWhenHidden: r, fetch: y } = t, v = K(t, ["signal", "headers", "onopen", "onmessage", "onclose", "onerror", "openWhenHidden", "fetch"]);
  return new Promise(($, F) => {
    const m = Object.assign({}, i);
    m.accept || (m.accept = R);
    let S;
    function C() {
      S.abort(), document.hidden || T();
    }
    r || document.addEventListener("visibilitychange", C);
    let E = Q, O = 0;
    function B() {
      document.removeEventListener("visibilitychange", C), window.clearTimeout(O), S.abort();
    }
    a == null || a.addEventListener("abort", () => {
      B(), $();
    });
    const x = y ?? window.fetch, H = c ?? Z;
    async function T() {
      var k;
      S = new AbortController();
      try {
        const A = await x(e, Object.assign(Object.assign({}, v), { headers: m, signal: S.signal }));
        await H(A), await J(A.body, X(M((p) => {
          p ? m[D] = p : delete m[D];
        }, (p) => {
          E = p;
        }, f))), d == null || d(), B(), $();
      } catch (A) {
        if (!S.signal.aborted)
          try {
            const p = (k = o == null ? void 0 : o(A)) !== null && k !== void 0 ? k : E;
            window.clearTimeout(O), O = window.setTimeout(T, p);
          } catch (p) {
            B(), F(p);
          }
      }
    }
    T();
  });
}
function Z(e) {
  const t = e.headers.get("content-type");
  if (!(t != null && t.startsWith(R)))
    throw new Error(`Expected content-type to be ${R}, Actual: ${t}`);
}
const P = "/memory", re = {
  ai: async (e, t, a) => {
    const i = await n(`${P}/ai-stream`, t);
    return (u.fetchEventSource ?? Y)(
      `${i.baseURL}${i.url}`,
      {
        method: "POST",
        body: JSON.stringify(i.data),
        headers: i.headers,
        openWhenHidden: !0,
        ...a,
        onmessage: e
      }
    );
  },
  stop: async (e) => s.post(
    await n(`${P}/stop`, e)
  ),
  feedback: async (e) => s.patch(
    await n(`${P}/feedback`, e)
  ),
  suggestions: async (e) => s.get(
    await n(`${P}/suggestions`, e)
  )
}, de = {
  getStatus: async (e, t, a) => s.get(
    await n(
      `/recordings/${e}/recurrence/${t}/status`,
      { ...a, version: "v2.0" }
    )
  ),
  getRecording: async (e, t, a) => s.get(
    await n(
      `/recordings/${e}/recurrence/${t}/recording`,
      { ...a, version: "v2.0" }
    )
  ),
  getPeople: async (e, t, a) => s.get(
    await n(
      `/recordings/${e}/recurrence/${t}/people`,
      { ...a, version: "v2.0" }
    )
  ),
  getEpisodes: async (e, t) => s.get(
    await n(`/recordings/${e}/episodes`, {
      ...t,
      version: "v2.0"
    })
  ),
  getSessionRecordings: async (e, t, a) => s.get(
    await n(
      `/recordings/by-session/${e}/${t}`,
      a
    )
  ),
  getLatestPlayable: async (e) => s.get(
    await n("/recordings/latest-playable", e)
  )
}, ue = {
  getById: async (e, t) => b.fetch(
    await n(`/sessions/get/${e}`, {
      ...u.defaultBraidParams,
      ...t
    })
  ),
  getFeatured: async (e) => s.get(await g("/sessions/featured", e)),
  subscribe: async (e) => s.post(
    await n("/sessions/subscribe", e)
  ),
  unsubscribe: async (e) => s.post(
    await n("/sessions/unsubscribe", e)
  ),
  getCategories: async (e) => s.get(await n("/sessions/category", e)),
  create: async (e) => s.post(await n("/sessions", e)),
  update: async (e, t, a) => s.patch(
    await n(
      `/sessions/${e}/recurrence/${t}`,
      a
    )
  ),
  end: async (e) => s.post(await n(`/meeting/${e}/end`)),
  delete: async (e, t, a) => s.patch(
    await n(
      `sessions/${e}/recurrence/${t}`,
      a
    )
  ),
  getPast: async (e) => s.get(
    await n("/sessions/get-past-sessions", e)
  ),
  getFuture: async (e) => s.get(
    await n("/sessions/get-future-sessions", e)
  ),
  restartSession: async (e, t, a) => s.patch(
    await n(
      `/sessions/${e}/recurrence/${t}/recur`,
      a
    )
  ),
  getSessionsLibraryHistory: async (e) => s.get(
    await n("/sessions/library/history", e)
  ),
  getSessionsLibraryUpcoming: async (e) => s.get(
    await n("/sessions/library/upcoming", e)
  ),
  presence: async (e, t) => b.fetch(
    await n(`/sessions/presence/${e}`, {
      ...u.defaultBraidParams,
      ...t
    })
  )
}, w = "/sessions/access", ge = {
  inReview: async (e) => b.fetch(
    await n(`${w}/in-review`, {
      ...u.defaultBraidParams,
      ...e
    })
  ),
  userSessionRequest: async (e) => b.fetch(
    await n(`${w}/get`, {
      ...u.defaultBraidParams,
      ...e
    })
  ),
  getViewer: async (e) => s.get(await n(`${w}/viewer`, e)),
  getViewerAccessStats: async (e) => s.get(
    await n(`${w}/get-viewer-access-stats`, e)
  ),
  getInReviewViewerAccessStats: async (e) => s.post(
    await n(
      `${w}/get-in-review-access-requests-count`,
      e
    )
  ),
  requestAccess: async (e) => s.post(
    await n(`${w}/request-access`, e)
  ),
  update: async (e) => s.put(await n(`${w}/request`, e)),
  add: async (e) => s.put(await n(`${w}/add`, e)),
  remove: async (e) => s.delete(await n(`${w}/remove`, e))
}, q = "/session-recordings/off-the-record", le = {
  enableOffTheRecord: async (e) => s.post(
    await n(`${q}/enable`, {
      version: "v2.0",
      ...e
    })
  ),
  disableOffTheRecord: async (e) => s.post(
    await n(`${q}/disable`, {
      version: "v2.0",
      ...e
    })
  )
}, ye = {
  getPlans: async (e) => s.get(await g("/billing/plans", { ...e })),
  getPlan: async (e, t) => s.get(
    await g(`/billing/plans/${e}`, {
      ...t
    })
  )
}, we = {
  getByUserId: async (e, t, a, i = "audience") => s.post(
    await n("/agora/token", {
      data: {
        userId: t,
        sessionId: e,
        role: i,
        expirationTs: a,
        tokenType: "userAccount"
      }
    })
  ),
  getByUid: async (e, t, a, i = "audience") => s.post(
    await n("/agora/token", {
      data: {
        sessionId: e,
        uid: t,
        role: i,
        expirationTs: a,
        tokenType: "uid"
      }
    })
  ),
  getChat: async (e, t, a) => s.get(
    await n("/chat/token", {
      ...a,
      params: {
        ...a == null ? void 0 : a.params,
        sessionID: e,
        sessionRecurrenceID: t
      }
    })
  ),
  getStream: async (e, t) => s.post(
    await n(`/meeting/${e}/token`, t)
  )
}, fe = {
  getById: async (e, t) => s.get(await n(`/users/id/${e}`, t)),
  getCurrent: async (e) => s.get(await n("/users/me", e)),
  update: async (e, { data: t, ...a }) => s.put(
    await n(`/users/id/${e}`, {
      ...a,
      data: {
        userData: t
      }
    })
  ),
  getSubscriptionPlan: async (e, t) => s.get(
    await n(`/users/${e}/plan`, {
      ...t
    })
  ),
  updateSubscriptionPlan: async (e, t) => s.patch(
    await n(`/users/${e}/plan`, {
      ...t
    })
  ),
  reactivateSubscriptionPlan: async (e, t) => s.post(
    await n(`/users/${e}/plan/reactivate`, {
      ...t
    })
  ),
  cancelSubscriptionPlan: async (e, t) => s.post(
    await n(`/users/${e}/plan/cancel`, {
      ...t
    })
  ),
  updatePaymentTransaction: async (e, t) => s.get(
    await n(
      `/users/${e}/update-payment-method-transaction`,
      {
        ...t
      }
    )
  ),
  activateTrial: async (e, t) => s.post(
    await n(`/users/${e}/activate-trial`, {
      ...t
    })
  ),
  createCustomer: async (e, t) => s.post(
    await n(`/users/${e}/customer`, {
      ...t
    })
  ),
  getTransactions: async (e, t) => s.get(
    await n(`/users/${e}/transactions`, {
      ...t
    })
  ),
  getTransactionInvoice: async (e, t, a) => s.get(
    await n(
      `/users/${t}/transactions/${e}/invoice`,
      { ...a }
    )
  ),
  getPaymentDetails: async (e, t) => s.get(
    await n(`/users/${e}/payment-method-details`, {
      ...t
    })
  ),
  requestDelete: async () => s.delete(await n("/users/me"))
}, pe = {
  get: async (e) => s.get(await n("/meeting-types", e))
}, ve = (e) => e == null ? void 0 : e.data, he = (e) => {
  var t;
  return (t = e == null ? void 0 : e.data) == null ? void 0 : t.data;
}, be = (e) => {
  var c, f;
  if (!e) return;
  let t, a, i = !1;
  if ("headers" in e && "error" in e) {
    const {
      errCode: d,
      statusCode: o,
      message: r
    } = e.error || {};
    t = d || o || e.code || ((c = e.data) == null ? void 0 : c.code), a = r || ((f = e.data) == null ? void 0 : f.message);
  } else {
    let {
      code: d,
      message: o,
      success: r
    } = "data" in e ? (e == null ? void 0 : e.data) || {} : e || {};
    "message" in e && o === void 0 && ({ code: d, message: o, success: r } = e), t = d, a = o, i = !!r;
  }
  return {
    code: t,
    message: a,
    success: i
  };
};
export {
  se as aiApiService,
  ie as authApiService,
  ne as authBaseEndpoint,
  Se as braidService,
  g as buildApiParams,
  n as buildApiParamsWithAuth,
  z as buildHeaders,
  u as config,
  ve as getApiData,
  he as getApiResponseData,
  V as getAuthHeader,
  _ as getBaseURL,
  be as getResponse,
  Pe as httpService,
  ce as imageApiService,
  ae as initCommonApi,
  oe as integrationsApiService,
  re as memoryApiService,
  de as recordingApiService,
  ge as sessionAccessApiService,
  ue as sessionApiService,
  le as sessionRecordingsApiService,
  ye as subscriptionPlanApiService,
  we as tokenApiService,
  N as updateConfig,
  fe as userApiService,
  pe as userMeetingTypesService
};
