import { AuthState } from '@waitroom/auth';
import {
  authService,
  axiosAuthInterceptor,
  braidOnErrorInterceptor,
  featureFlagsService,
  getAccessToken,
  initConfig as initCommon,
  updateAuthState,
} from '@waitroom/common';
import { braidService, httpService, initCommonApi } from '@waitroom/common-api';
import { errorService, logger, LogLevel } from '@waitroom/error-service';
import { config } from '.';
import { envs } from '../modules/core/services/envs';
import store from '../modules/core/services/redux/configureStore';
import { sentryService } from '../modules/core/services/sentry';
import { storageService } from '../modules/core/services/storage';
import { getFingerPrint } from '../modules/core/utils/finger-print';
import { getClientDetails } from './clientDetails';
const getFP = getFingerPrint();

// setup logger
logger.enabled = !config.isProd;
logger.logLevel = Number(envs.VITE_LOG_LEVEL ?? 3) as LogLevel;
logger.logWith(1, `ENV: ${config.customEnv}`);
// enable all feature flags
featureFlagsService.override = config.isDev ? true : undefined;
// setup sentry and error service
sentryService.init();

const onAuthUpdated = (state: AuthState) => {
  // update auth state
  store.dispatch(updateAuthState(state));
};
// setup auth interceptor for token refresh
httpService.client.interceptors.response.use(
  (r) => r,
  axiosAuthInterceptor({
    onAuthUpdated,
  }),
);
// setup braid interceptor for token refresh
braidService.config.interceptors.onError = braidOnErrorInterceptor({
  onAuthUpdated,
});
// setup auth service
authService.init({ storage: storageService });

// init api config
initCommonApi({
  baseURL: config.domains.wormhole,
  version: 'v1.0',
  getAuthToken: async () =>
    (await getAccessToken(undefined, (state) => {
      // update auth state
      store.dispatch(updateAuthState(state));
    })) || '',
  getFingerPrint: getFP,
  getClientDetails,
});

// init common config
initCommon({
  domains: {
    luxor: config.domains.luxor,
    wormhole: config.domains.wormhole,
  },
  featureFlags: config.featureFlags,
  getFingerPrint: getFP,
  getClientDetails: getClientDetails(),
  storage: storageService,
  dateTime: config.dateTime,
  reportError: errorService.report,
  session: {
    maxSpeakers: config.session.speakers.max,
  },
});
