import {
  AnyAction,
  createListenerMiddleware,
  ListenerEffectAPI,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import {
  AUTHENTICATED,
  CommonState,
  selectAuthState,
  SetupAuthAction,
} from '@waitroom/common';
import { connectUserToBraze } from '../../core/services/braze';

export type ListenerApi = ListenerEffectAPI<
  CommonState,
  ThunkDispatch<CommonState, unknown, SetupAuthAction | AnyAction>,
  unknown
>;
const authListenerMiddleware = createListenerMiddleware<
  CommonState,
  ThunkDispatch<CommonState, unknown, SetupAuthAction | AnyAction>
>();

authListenerMiddleware.startListening({
  type: AUTHENTICATED,
  effect: async (_action, listenerApi) => {
    // cancel other running instances
    listenerApi.cancelActiveListeners();

    const state = selectAuthState(listenerApi.getState());
    connectUserToBraze(state);
  },
});

export { authListenerMiddleware };
